import React, { useState } from 'react'
import { ProgressSteps, NumberedStep } from 'baseui/progress-steps'
import { Button, SHAPE } from 'baseui/button'
import { Paragraph4 } from 'baseui/typography'
import { useStyletron } from 'baseui'

const Steps = ({ changeFocus }) => {
  const [current, setCurrent] = useState(0)
  const [css] = useStyletron()

  const handleStepChange = (step, focusElement) => {
    if (step) setCurrent(step)
    if (focusElement) changeFocus(focusElement)
  }

  return (
    <div
      className={css({
        maxWidth: '450px',
        height: '100%',
      })}
    >
      <h2 style={{ textAlign: 'center', color: '#fff' }}>
        Como criar seu link WhatsApp
      </h2>
      <ProgressSteps
        current={current}
        overrides={{
          Root: {
            style: {
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
            },
          },
        }}
      >
        <NumberedStep title="Digite seu número de telefone WhatsApp">
          <Paragraph4>
            Digite o número de telefone onde você deseja ser contatado.
            Lembre-se de verificar o código de seu país.
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(1)}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Próximo
          </Button>
        </NumberedStep>
        <NumberedStep title="Adicione uma mensagem personalizada que os usuários enviarão a você">
          <Paragraph4>
            Esta mensagem é opcional, mas é uma ótima maneira de personalizar
            seu link, por exemplo, "Olá, quero mais informações".
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(2)}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Próximo
          </Button>
        </NumberedStep>
        <NumberedStep title="Clique em “Gerar meu wa.link”, copie-o e use-o onde você quiser!">
          <Paragraph4>
            O gerador cria um link abreviado e também um código QR para que você
            faça o download.
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(null, 'phone')}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Vamos começar!
          </Button>
        </NumberedStep>
      </ProgressSteps>
    </div>
  )
}

export default Steps
