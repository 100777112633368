import React, { useState } from 'react'
import { Link } from 'gatsby'
import { H2, Paragraph2, H3 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import Layout from '../components/layout'
import GeneratorWrapper from '../components/GeneratorWrapper'
import Faq from '../components/faq'
import SEO from '../components/seo'
import Steps from '../components/steps'
import Hero from '../components/hero'
import CTA from '../components/RegisterCTA'
import Pricing from '../components/Pricing'
import Emoji from '../components/Emoji'
import PricingBanner from '../components/PricingBanner'
import { FiExternalLink, FiArrowDown } from 'react-icons/fi'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { isMobile } from 'react-device-detect'
import SearchLink from '../components/SearchLink'

const IndexPage = () => {
  const [css] = useStyletron()
  const [focusElement, setFocusElement] = useState('')

  const centerText = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const actionCard = css({
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    // padding: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '15px',
    margin: '15px',
    minWidth: '220px',
    textDecoration: 'none',
    backgroundColor: '#fff',
  })

  return (
    <Layout hero={<Hero />} page="home">
      <SEO
        title="Crie links no WhatsApp"
        hreflang={[
          { rel: 'alternate', hrefLang: 'pt', href: 'https://criar.wa.link' },
          { rel: 'alternate', hrefLang: 'en', href: 'https://create.wa.link' },
          { rel: 'alternate', hrefLang: 'es', href: 'https://crear.wa.link' },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link',
          },
        ]}
      />
      {/* From here */}
      <div
        className={css({
          marginTop: '3rem',
          paddingBottom: '3rem',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
            marginBottom: '2rem',
          })}
        >
          O que você gostaria de fazer hoje?
        </h2>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: 'rgb(238, 238, 238);',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            borderRadius: '15px',
          })}
        >
          <ActionPopover content="Crie links do WhatsApp com uma mensagem personalizada, de graça">
            <a
              href="/"
              style={{}}
              onClick={e => {
                e.preventDefault()
                scrollTo('#create-link')
              }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'😁'} /> Crie um wa.link gratuito
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover
            content={
              <>
                Por <strong>$6/ano</strong>, obtenha um wa.link com marca e com
                informações atualizáveis, análises, pesquisas e mais...
              </>
            }
          >
            <a
              href="#premium"
              style={{
                textDecoration: 'none',
                textAlign: 'center',
              }}
              className={actionCard}
              onClick={e => {
                e.preventDefault()
                scrollTo('#premium')
              }}
            >
              <p>
                <Emoji symbol={'👑'} /> Obtenha um wa.link de marca
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover content="Abrir chats WhatsApp sem salvar contatos">
            <a
              href="https://open.wa.link"
              style={{ textDecoration: 'none' }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'💬'} /> Abrir chats do WhatsApp
                <span style={{ marginLeft: '5px' }}>
                  <FiExternalLink size="16" />
                </span>
              </p>
            </a>
          </ActionPopover>
        </div>
      </div>
      <div
        className={css({
          // margin: '4rem 0',
          margin: '3rem 0 4rem 0',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
          })}
        >
          Por que criar links do WhatsApp com o Walink?
        </h2>
        <div className={centerText}>
          <Paragraph2>
            Para abrir um chat no WhatsApp, você geralmente tem que conhecer o
            número de celular do usuário, salvá-lo no dispositivo e depois
            procurá-lo para iniciar a conversa. Um link WhatsApp resume todo
            este processo em 1 clique que abre o chat sem a necessidade de
            salvar números, isto é chamado{' '}
            <strong>"conversa em um clique".</strong>
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            Se você está procurando criar links WhatsApp para que as pessoas
            possam entrar em contato com você instantaneamente, você está no
            lugar certo. Com o Walink você pode gerar links WhatsApp
            gratuitamente com <strong>domínio wa.link</strong> e{' '}
            <strong>código QR</strong> em menos de 1 minuto, sem necessidade de
            cadastrar-se.
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            Além disso, você pode ter links oficiais para seu negócio ao assinar
            o Walink Premium para criar links personalizados do WhatsApp como
            <strong> wa.link/SuaMarca</strong> que trarão muito mais clientes
            para o chat, terá links analíticos de clique, atualizar informações
            do link, multi-agentes, formulários e muito mais.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          margin: '50px auto',
          background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
          minHeight: '310px',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          borderRadius: '10px',
        })}
      >
        <Steps changeFocus={setFocusElement} />
      </div>
      <div
        id="create-link"
        className={css({
          paddingTop: '10px',
        })}
      >
        <H2 className={css({ textAlign: 'center' })}>
          Crie seu link gratuito aqui
        </H2>
        <GeneratorWrapper focusElement={focusElement} />
      </div>

      <div
        className={css({ marginTop: '3rem', marginBottom: '1rem' })}
        id="premium"
      >
        <H3 className={css({ textAlign: 'center' })}>
          Encontre um wa.link para sua marca
        </H3>
        <SearchLink showPremium={true} />
      </div>
      <div className={css({ marginTop: '60px', marginBottom: '60px' })}>
        <Pricing />
        <PricingBanner />
      </div>
      <div className={css({ marginTop: '50px' })}>
        <div className={css({ margin: '20px' })}>
          <H2>FAQs - Perguntas mais frequentes:</H2>
        </div>
        <Faq />
        <CTA
          title="Você quer um link com marca?"
          description={
            <>
              Com o{' '}
              <Link
                to="/premium"
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Walink Premium
              </Link>{' '}
              você pode criar, rastrear e modificar seus links personalizados do
              WhatsApp. <strong>(wa.link/SuaMarca)</strong>
            </>
          }
          currentPage="Home"
        />
      </div>
    </Layout>
  )
}

function ActionPopover({ children, content }) {
  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}

          <StatefulPopover
            showArrow
            content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.click}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '250px',
                  textAlign: 'center',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <span
              style={{
                verticalAlign: 'middle',
                marginRight: '15px',
                fontStyle: 'italic',
              }}
            >
              <strong>i</strong>
            </span>
          </StatefulPopover>
        </div>
      ) : (
        <StatefulPopover
          showArrow
          content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
          accessibilityType={'tooltip'}
          triggerType={TRIGGER_TYPE.hover}
          placement={PLACEMENT.bottom}
          overrides={{
            Body: {
              style: {
                width: '250px',
                textAlign: 'center',
                marginTop: '10px',
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            Inner: {
              style: {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
          }}
        >
          {children}
        </StatefulPopover>
      )}
    </>
  )
}

export default IndexPage
