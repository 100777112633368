import React from 'react'
import { Accordion, Panel } from 'baseui/accordion'
import { useStyletron } from 'baseui'

const Faq = () => {
  const [css] = useStyletron()

  return (
    <div className={css({ marginBottom: '20px', minHeight: '300px' })}>
      <Accordion renderAll>
        <Panel title="Quanto a Walink cobra por links?">
          Os links que você cria com o gerador em create.wa.link são totalmente{' '}
          <strong>gratuitos.</strong>
          <br />
          <br />
          Você também pode acessar o Walink Premium e{' '}
          <strong>criar links de marca por 6 USD por ano </strong>que incluem
          mais recursos como a URL personalizada e um painel de administração
          para editar as informações do link e visualizar as análises.{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Registre-se agora para comprar o seu primeiro Link Premium aqui.
          </a>
        </Panel>
        <Panel title="Qual é a mensagem personalizada?">
          É uma mensagem padrão que aparece no campo de entrada de texto do
          usuário quando ele clica no seu link e abre o chat. Desta forma, é
          mais fácil para ele iniciar uma conversa e você saberá exatamente de
          onde o usuário veio.
        </Panel>
        <Panel title="Como adicionar um link WhatsApp (wa.link) à biografia da Instagram?">
          Um dos lugares mais comuns para usar seus links WhatsApp gerados com o
          Walink, é o perfil Instagram. Observe que essa rede social só permite
          1 link na biografia, então se você já tem um, você terá que alterá-lo.
          <br />
          <br />
          Vá ao seu perfil do Instagram, clique em "Editar Perfil", e preencha o
          campo "Website" com wa.link que você acabou de criar.
          <br />
          <br />
          Lembre-se que se você não preencher o campo "Website", seus usuários
          não poderão clicar no link e você perderá a oportunidade de um contato
          imediato com seus clientes.
        </Panel>
        <Panel title="Por que existe um website perguntando se eu quero enviar uma mensagem?">
          Algumas vezes, especialmente no desktop ou laptop, quando um usuário
          clica em um link WhatsApp, o navegador abrirá uma nova aba onde a
          pessoa é perguntada se deseja enviar uma mensagem para
          ####-##-###-##### Número WhatsApp. É assim que o WhatsApp mantém os
          links seguros para os usuários, portanto, se isso estiver acontecendo
          em seus links, você pode descansar e garantir que está funcionando
          perfeitamente.
        </Panel>
        <Panel title="Como posso criar um link com uma URL personalizada (de marca)?">
          URLs de links gerados gratuitamente são atribuídas aleatoriamente,
          usando números e letras. Se você gostaria de criar um link com URLs
          personalizadas como <strong>wa.link/SuaMarca</strong>, você pode{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            se registrar no Walink Premium
          </a>{' '}
          para obter todos os benefícios, incluindo URLs personalizadas.
        </Panel>

        <Panel title="Posso criar um link para meu catálogo no WhatsApp Business?">
          Links criados no gerador gratuito são um acesso direto ao chat
          WhatsApp e não são habilitados no catálogo de negócios.
          <br />
          <br />
          Para criar um link personalizado como{' '}
          <strong>wa.link/MeuCatalogo</strong> que leva diretamente ao seu
          catálogo WhatsApp Business, você pode{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            se registrar no Walink Premium
          </a>{' '}
          para obter todos os benefícios, incluindo links para seu catálogo.
        </Panel>
        <Panel title="Como posso saber quantos cliques meus links estão recebendo?">
          Os links gerados gratuitamente não suportam opções analíticas, então
          você não pode saber quantos cliques esses links estão recebendo.
          <br />
          <br />
          <strong>O Walink Premium</strong> oferece análises e muitas outras
          funções.{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Cadastre-se aqui para obter todos os benefícios dos links Premium.
          </a>
        </Panel>
        <Panel title="O Walink trabalha em meu país?">
          Os links gerados pelo Walink funcionam em qualquer país onde o
          WhatsApp está oficialmente disponível.
        </Panel>
        <Panel title="Posso apagar um wa.link?">
          Se você acabou de criar um wa.link e perceber que você adicionou a
          informação errada ou simplesmente não vai usá-lo, você não precisa
          apagá-lo.{' '}
          <strong>Apenas crie um novo link com a nova informação</strong> e não
          use/compartilhe o anterior. <br />
          <br />
          Se por algum motivo você precisar excluir um wa.link para que ele não
          esteja mais disponível ao público, você pode fazê-lo preenchendo as
          informações{' '}
          <a
            href="https://walink.io/request-delete-link"
            target="_blank"
            rel="noopener noreferrer"
            alt="delete link"
          >
            aqui{' '}
          </a>
          . Tenha em mente que, para excluir o wa.link, você deve provar que é o
          proprietário do número de telefone associado ao link.
        </Panel>
        <Panel title="Onde posso relatar um bug ou problema?">
          Os links gerados gratuitamente não oferecem suporte personalizado.
          Entretanto, se você encontrar um bug ou qualquer problema usando
          Walink, você pode nos contatar no X{' '}
          <a
            href="https://x.com/walink_inc"
            target="_blank"
            rel="noopener noreferrer"
            alt="@walink_inc"
          >
            @walink_inc
          </a>
          . Registre-se no{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Walink Premium
          </a>{' '}
          para criar seus próprios links de marca e receber suporte
          personalizado.
        </Panel>
      </Accordion>
    </div>
  )
}

export default Faq
